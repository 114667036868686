import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-password-wrapper" }
const _hoisted_2 = { class: "input-password__icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["value", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        src: $setup.pathEye,
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showPassword = !$setup.showPassword))
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("input", _mergeProps({ class: "input-password" }, _ctx.$attrs, {
      class: $props.inputClasses,
      value: $props.modelValue,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      type: $setup.showPassword ? 'text': 'password'
    }), null, 16, _hoisted_4)
  ]))
}